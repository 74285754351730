import React, { useState } from "react";
import { categories, difficultySettings } from "./App";
import { ArrowLeft } from "lucide-react";

const GameInterface = ({ gameState, gameActions, handleAnswerClick }) => {
  const {
    gamePhase,
    selectedCategories,
    selectedDifficulty,
    currentQuestion,
    shuffledQuestions,
  } = gameState;

  const [selectedOption, setSelectedOption] = useState(null);
  const [hasCheckedAnswer, setHasCheckedAnswer] = useState(false);

  const handleOptionSelect = (index) => {
    if (!hasCheckedAnswer) {
      setSelectedOption(index);
    }
  };

  const checkAnswer = () => {
    if (selectedOption !== null && !hasCheckedAnswer) {
      setHasCheckedAnswer(true);
      handleAnswerClick(selectedOption, false);
    }
  };

  const handleNext = () => {
    gameActions.nextQuestion();
    setSelectedOption(null);
    setHasCheckedAnswer(false);
  };

  const NavigationHeader = ({ onBack, showBack = true }) => (
    <div className="flex justify-between items-center mb-8">
      <div className="flex items-center gap-4">
        <img src="/assets/logo.svg" alt="Logo" className="h-8 w-8" />
        <button
          onClick={() => gameActions.resetGame()}
          className="text-white/70 hover:text-white flex items-center gap-2"
        >
          <span>Home</span>
        </button>
      </div>
      {showBack && (
        <button
          onClick={onBack}
          className="text-white/70 hover:text-white flex items-center gap-2"
        >
          <ArrowLeft size={20} />
          <span>Back</span>
        </button>
      )}
    </div>
  );

  if (gamePhase === "category") {
    return (
      <div className="min-h-screen bg-[#1a1f2e] p-8 pt-12 flex flex-col">
        <div className="w-full max-w-md mx-auto">
          <NavigationHeader onBack={() => gameActions.resetGame()} />
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-white text-2xl font-medium">
              Choose Categories
            </h2>
            <span className="text-white/70 text-lg">
              {selectedCategories.length}/4
            </span>
          </div>
          <div className="w-full border-b border-white/30 mb-8" />
          <div className="space-y-3">
            {categories.map((cat) => (
              <button
                key={cat.name}
                onClick={() => gameActions.selectCategory(cat.name)}
                className={`w-full p-4 rounded-lg text-left transition-all duration-200
                  ${
                    selectedCategories.includes(cat.name)
                      ? "bg-blue-600 text-white border-2 border-blue-400"
                      : "bg-[#2a2f3e] text-white hover:bg-[#3a3f4e] border-2 border-transparent"
                  }`}
              >
                <span className="text-lg capitalize">{cat.name}</span>
              </button>
            ))}
          </div>
          {selectedCategories.length > 0 && (
            <button
              onClick={() => gameActions.setGamePhase("difficulty")}
              className="w-full mt-8 py-4 rounded-lg bg-blue-600 text-white text-lg font-medium hover:bg-blue-700 transition-all duration-200"
            >
              Next
            </button>
          )}
        </div>
      </div>
    );
  }

  if (gamePhase === "difficulty") {
    return (
      <div className="min-h-screen bg-[#1a1f2e] p-8 pt-12 flex flex-col">
        <div className="w-full max-w-md mx-auto">
          <NavigationHeader
            onBack={() => gameActions.setGamePhase("category")}
          />
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-white text-2xl font-medium">
              Select Difficulties
            </h2>
            <span className="text-white/70 text-lg">
              {selectedDifficulty.length}/3
            </span>
          </div>
          <div className="w-full border-b border-white/30 mb-8" />
          <div className="space-y-3">
            {Object.keys(difficultySettings).map((diff) => (
              <button
                key={diff}
                onClick={() => gameActions.selectDifficulty(diff)}
                className={`w-full p-4 rounded-lg text-left transition-all duration-200
                  ${
                    selectedDifficulty.includes(diff)
                      ? "bg-blue-600 text-white border-2 border-blue-400"
                      : "bg-[#2a2f3e] text-white hover:bg-[#3a3f4e] border-2 border-transparent"
                  }`}
              >
                <span className="text-lg capitalize">{diff}</span>
              </button>
            ))}
          </div>
          {selectedDifficulty.length > 0 && (
            <button
              onClick={() => gameActions.setGamePhase("playing")}
              className="w-full mt-8 py-4 rounded-lg bg-blue-600 text-white text-lg font-medium hover:bg-blue-700 transition-all duration-200"
            >
              Start Game
            </button>
          )}
        </div>
      </div>
    );
  }

  if (!shuffledQuestions || shuffledQuestions.length === 0) {
    return (
      <div className="min-h-screen bg-[#1a1f2e] p-8 pt-12 flex flex-col">
        <div className="w-full max-w-md mx-auto">
          <NavigationHeader showBack={false} />
          <p className="text-white text-center mt-8">Loading questions...</p>
        </div>
      </div>
    );
  }

  const currentQ = shuffledQuestions[currentQuestion];

  return (
    <div className="min-h-screen bg-[#1a1f2e] p-8 pt-12 flex flex-col">
      <div className="w-full max-w-md mx-auto">
        <NavigationHeader showBack={false} />

        <div className="flex justify-between items-center mb-4">
          <span className="text-white/70 text-lg">
            Question {currentQuestion + 1}/{shuffledQuestions.length}
          </span>
          <button
            onClick={() => {
              setSelectedOption(null);
              setHasCheckedAnswer(false);
              gameActions.previousQuestion();
            }}
            disabled={currentQuestion === 0}
            className={`text-white/70 hover:text-white transition-colors ${
              currentQuestion === 0 ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            Previous Card
          </button>
        </div>
        <div className="w-full border-b border-white/30 mb-8" />

        <div className="mb-8">
          <h2 className="text-white text-2xl font-medium mb-8">
            {currentQ.question}
          </h2>

          <div className="space-y-3">
            {currentQ.options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleOptionSelect(index)}
                disabled={hasCheckedAnswer}
                className={`w-full p-4 rounded-lg text-left transition-all duration-200
                  ${
                    hasCheckedAnswer && option === currentQ.answer
                      ? "bg-green-600 text-white border-2 border-green-400"
                      : hasCheckedAnswer && selectedOption === index
                      ? "bg-red-600 text-white border-2 border-red-400"
                      : selectedOption === index
                      ? "bg-blue-600 text-white border-2 border-blue-400"
                      : "bg-[#2a2f3e] text-white hover:bg-[#3a3f4e] border-2 border-transparent"
                  }
                  ${
                    hasCheckedAnswer &&
                    selectedOption !== index &&
                    option !== currentQ.answer
                      ? "opacity-50"
                      : ""
                  }
                `}
              >
                <div className="flex items-center gap-3">
                  <div
                    className={`w-6 h-6 rounded-full border-2 flex items-center justify-center
                    ${
                      selectedOption === index
                        ? "border-white"
                        : "border-gray-400"
                    }`}
                  >
                    {selectedOption === index && (
                      <div className="w-3 h-3 bg-white rounded-full" />
                    )}
                  </div>
                  <span className="text-lg">{option}</span>
                </div>
              </button>
            ))}
          </div>
        </div>

        <div className="mt-auto">
          {!hasCheckedAnswer ? (
            <button
              onClick={checkAnswer}
              disabled={selectedOption === null}
              className={`w-full py-4 rounded-lg text-center text-white text-lg font-medium transition-all duration-200
                ${
                  selectedOption !== null
                    ? "bg-blue-600 hover:bg-blue-700"
                    : "bg-gray-600 opacity-50 cursor-not-allowed"
                }`}
            >
              Check Answer
            </button>
          ) : (
            <button
              onClick={handleNext}
              className="w-full py-4 rounded-lg bg-blue-600 text-white text-lg font-medium hover:bg-blue-700 transition-all duration-200"
            >
              Next Question
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default GameInterface;
