import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import supabase from "./supabase";
import LandingPage from "./LandingPage";
import GameInterface from "./GameInterface";
import EndGamePage from "./EndGamePage";

export const difficultySettings = {
  easy: { pointsPerQuestion: 1 },
  medium: { pointsPerQuestion: 2 },
  hard: { pointsPerQuestion: 3 },
};

export const categories = [
  { name: "quran", color: "#ff5757" },
  { name: "seerah", color: "#5271ff" },
  { name: "prophets", color: "#7ed957" },
  { name: "sahabah", color: "#ffde59" },
];

function App() {
  const [gamePhase, setGamePhase] = useState("landing");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedDifficulty, setSelectedDifficulty] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [streak, setStreak] = useState(0);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);

  useEffect(() => {
    async function getQuestions() {
      const { data: questions, error } = await supabase
        .from("cards")
        .select("*");
      if (!error) {
        const formattedQuestions = questions.map((question) => ({
          question: question.question,
          options: question.options,
          answer: question.answer,
          category: question.category,
          difficulty: question.difficulty,
        }));
        setQuestions(formattedQuestions);
      }
    }
    getQuestions();
  }, []);

  useEffect(() => {
    if (
      questions.length > 0 &&
      selectedCategories.length > 0 &&
      selectedDifficulty.length > 0
    ) {
      const filteredQuestions = questions.filter(
        (question) =>
          selectedCategories.includes(question.category) &&
          selectedDifficulty.includes(question.difficulty)
      );
      setShuffledQuestions(shuffleArray(filteredQuestions));
    }
  }, [questions, selectedCategories, selectedDifficulty]);

  const handleAnswerClick = (optionIndex, autoAdvance = false) => {
    if (selectedAnswer !== null) return;

    setSelectedAnswer(optionIndex);
    const currentQ = shuffledQuestions[currentQuestion];
    const correct = currentQ.options[optionIndex] === currentQ.answer;
    setIsCorrect(correct);

    if (correct) {
      const points = difficultySettings[currentQ.difficulty].pointsPerQuestion;
      const streakBonus = streak >= 2 ? Math.floor(points * 0.5) : 0;
      setScore(score + points + streakBonus);
      setStreak(streak + 1);
    } else {
      setStreak(0);
    }

    if (autoAdvance) {
      setTimeout(() => {
        if (currentQuestion === shuffledQuestions.length - 1) {
          setGamePhase("end");
        } else {
          setCurrentQuestion(currentQuestion + 1);
          setSelectedAnswer(null);
          setIsCorrect(null);
        }
      }, 1500);
    }
  };

  const nextQuestion = () => {
    if (currentQuestion < shuffledQuestions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
      setSelectedAnswer(null);
      setIsCorrect(null);
    } else {
      setGamePhase("end");
    }
  };

  const previousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prev) => prev - 1);
      setSelectedAnswer(null);
      setIsCorrect(null);
    }
  };

  function shuffleArray(array) {
    let shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  }

  const gameActions = {
    resetGame: () => {
      setGamePhase("landing");
      setSelectedCategories([]);
      setSelectedDifficulty([]);
      setCurrentQuestion(0);
      setScore(0);
      setSelectedAnswer(null);
      setIsCorrect(null);
      setStreak(0);
    },
    startGame: () => setGamePhase("category"),
    selectCategory: (category) => {
      setSelectedCategories((prev) =>
        prev.includes(category)
          ? prev.filter((cat) => cat !== category)
          : [...prev, category]
      );
    },
    selectDifficulty: (difficulty) => {
      setSelectedDifficulty((prev) =>
        prev.includes(difficulty)
          ? prev.filter((diff) => diff !== difficulty)
          : [...prev, difficulty]
      );
    },
    setGamePhase,
    nextQuestion,
    previousQuestion,
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            gamePhase === "landing" ? (
              <LandingPage
                resetSelections={gameActions.resetGame}
                onStart={() => setGamePhase("category")}
              />
            ) : gamePhase === "end" ? (
              <EndGamePage resetGame={gameActions.resetGame} />
            ) : (
              <GameInterface
                gameState={{
                  gamePhase,
                  selectedCategories,
                  selectedDifficulty,
                  currentQuestion,
                  score,
                  selectedAnswer,
                  isCorrect,
                  streak,
                  shuffledQuestions,
                }}
                gameActions={gameActions}
                handleAnswerClick={handleAnswerClick}
              />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
