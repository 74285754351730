import React from "react";
import { Link } from "react-router-dom";

const LandingPage = ({ resetSelections, onStart }) => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-[#1a1f2e] via-[#232838] to-[#2a2f3e] flex flex-col justify-start items-center text-center text-white p-4 font-['Roboto'] pt-16">
      <div>
        <div className="mb-8">
          <img
            src="/assets/logo.svg"
            alt="Islamic Trivia Logo"
            className="h-40 w-auto filter drop-shadow-lg mx-auto"
          />
        </div>

        <h1 className="font-['DM_Serif_Text'] font-normal text-6xl md:text-8xl mb-6 text-white drop-shadow-md">
          Islamic Trivia
        </h1>

        <p className="text-lg md:text-2xl max-w-[90%] mx-auto leading-relaxed mb-8 drop-shadow">
          Learn about Islam through engaging trivia questions made for all
          levels and ages.
        </p>

        <button
          onClick={onStart}
          className="bg-blue-600 text-white font-medium py-3 px-8 rounded-lg text-xl hover:bg-blue-700 transition-colors"
        >
          Play Now
        </button>
      </div>
    </div>
  );
};

export default LandingPage;
